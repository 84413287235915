import { putRequest, getRequest, postBodyRequest } from '@/utils/axios'
// 新增下单
export function orderAdd (params) {
  return postBodyRequest('/cs/order/place-order', params)
}
// 订单列表
export function orderPage (params) {
  return postBodyRequest('/cs/order/page', params)
}
// 查看订单信息
export function orderInfo (orderId) {
  return getRequest(`/cs/order/${orderId}`)
}
// 订单编辑
export function orderEdit(params) {
  return postBodyRequest('/cs/order/edit', params)
}
// 推送订单
export function orderPush(orderId) {
  return postBodyRequest(`/cs/order/${orderId}/push`)
}
// 删除订单
export function orderDelete(orderId) {
  return postBodyRequest(`/cs/order/${orderId}/delete`)
}
//复制订单
export function orderCopy(orderId) {
  return postBodyRequest(`/cs/order/${orderId}/copy`)
}
// 查询订单的客户车辆信息
export function vehicleList(orderId) {
  return getRequest(`/cs/order/vehicle/${orderId}/vehicles`)
}
// 小车列表
export function vehiclePage(params) {
  return postBodyRequest(`/cs/order/vehicle/page`,params)
}
// 批量车辆交车
export function batchDelivery(params) {
  return postBodyRequest(`/cs/order/vehicle/batch/delivery`,params)
}
// 车辆附加费
export function batchvehicleFee(params) {
  return postBodyRequest(`/cs/order/vehicle-fee/batch`,params)
}
// 拆分订单
export function ordeSplitrVehicle(params) {
  return postBodyRequest(`/cs/order/split-vehicle`,params)
}

// 订单支付列表
export function orderpayList(orderId) {
  return getRequest(`/cs/orderpay/order-sand-pay/list?orderId=${orderId}`,)
}
// 车辆-运输信息
export function transportDetail(orderVehicleId) {
  return getRequest(`/cs/order/vehicle/transport-detail/${orderVehicleId}`,)
}
// 查看车辆交车信息
export function deliveryInfo(vehicleId) {
  return getRequest(`/cs/order/vehicle/${vehicleId}/delivery`)
}
// 承运商司机列表
export function driverList(params) {
  return postBodyRequest(`/cs/carrier/driver/list`,params)
}